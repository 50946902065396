export const PUBLISH = 0 as const
export type PUBLISH = typeof PUBLISH

export const SUBSCRIBE = 1 as const
export type SUBSCRIBE = typeof SUBSCRIBE

export const RESET = 2 as const
export type RESET = typeof RESET

export const VALUE = 4 as const
export type VALUE = typeof VALUE
